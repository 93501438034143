<template>
  <section class="auth--container">
    <div class="title--text">
      {{ $t('loginRegister.title') }}
    </div>
    <section-loader :show="isLoading" />
    <v-alert :type="alertType" :value="showAlert" dismissible>
      <div v-html="messageAlert" />
    </v-alert>
    <form id="loginForm" @submit.prevent="login">
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('email') }">
        <v-text-field
          v-model="email"
          :label="$t('loginRegister.email')"
          solo
          :hide-details="true"
          name="email"
        />
        <span v-if="validation.hasError('email')" class="val-error">{{
          validation.firstError('email')
        }}</span>
      </div>
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('password') }">
        <v-text-field
          v-model="password"
          :label="$t('loginRegister.pass')"
          solo
          :hide-details="true"
          name="password"
          :type="showPass ? 'text' : 'password'"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (showPass = !showPass)"
        />
        <span v-if="validation.hasError('password')" class="val-error">{{
          validation.firstError('password')
        }}</span>
      </div>
      <button :disabled="isLoading" type="submit" class="main--btn">
        {{ $t('loginRegister.login') }}
      </button>
    </form>
  </section>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  mixins: [HelperMixin],
  components: {
    SectionLoader,
  },
  props: ['formType'],
  data() {
    return {
      email: null,
      password: null,
      isLoading: false,
      remember: false,
      messageAlert: null,
      showAlert: false,
      alertType: 'error',
      showPass: false,
    };
  },
  validators: {
    email(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.email.required'))
        .email(this.$i18n.t('errors.loginRegister.email.invalid'))
        .maxLength(255, this.$i18n.t('errors.loginRegister.email.max', { maxChar: 255 }));
    },
    password(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.password.required'))
        .minLength(6, this.$i18n.t('errors.loginRegister.password.min', { minChar: 6 }))
        .maxLength(255, this.$i18n.t('errors.loginRegister.password.max', { maxChar: 255 }));
    },
  },
  mounted() {
    // this.openModal();
  },
  methods: {
    async login() {
      try {
        const self = this;
        this.hasError = false;
        this.dataError = null;
        this.isLoading = true;
        const isValid = await this.$validate();
        if (isValid) {
          this.isLoading = true;
          const response = await this.$store
            .dispatch('global/login', {
              fields: {
                email: this.email,
                password: this.password,
                remember: this.remember,
              },
            })
            .catch((e) => {
              self.hasError = true;
              self.dataError = e.response.data.error;
            });
          if (!this.hasError && response.error) {
            this.hasError = true;
            this.dataError = response.error;
          }
          if (!this.hasError) {
            this.isLoading = false;
            await this.$router.push('/');
          } else {
            const message = this.dataError.message;

            const type = this.dataError.type === 'validation' ? 'error' : 'success';

            this.showAlert = true;
            this.messageAlert = message;
            this.alertType = type;
            self.isLoading = false;
          }
        } else {
          this.scrollToErrorSection(this.$el);
        }

        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
