<template>
  <div class="p-4">
    <login form-type="page" />
  </div>
</template>

<script>
import Login from '@/components/auth/login';
import { mapState } from 'vuex';

export default {
  components: {
    Login,
  },
  computed: {
    ...mapState({}),
  },
  mounted() {},
  methods: {},
};
</script>
